<template>
  <div class="pa-5">
    Справочник наименований (спектакли, выставки, экскурсии и тп)
    <hr>

    <div v-if="showlist_ready">


      <template>
        <v-card
            class="mx-auto"
            max-width="500"
        >

          <v-list two-line>
            <v-list-item-group
                v-model="selected"
                active-class="orange--text"
            >
              <template v-for="(item, index) in showlist">
                <v-list-item :key="item.id">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.name"></v-list-item-title>

                      <v-list-item-subtitle
                          class="text--primary"
                          v-text="item.time"
                      ></v-list-item-subtitle>

                      <v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-action-text v-text="item.age"></v-list-item-action-text>


                    </v-list-item-action>
                  </template>
                </v-list-item>

                <v-divider
                    v-if="index < showlist.length - 1"
                    :key="index+'-line'"
                ></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>
      </template>


<!--      <template>-->
<!--        <div v-for="show in showlist" :key="show.id">-->
<!--          {{show.id}}  - {{show.tag_status_id}} - {{show.name}}-->
<!--        </div>-->

<!--      </template>-->

    </div>

  </div>
</template>

<script>

export default {
  name: "ShowList",

  data: () => ({

    showlist_ready: false,

    selected:null,
    showlist:[],

  }),

  created() {

    if (this.$store.getters.isAuthenticated){

      let pr = this.$store.state.api.loadShowList();

      pr.then( data =>{
        this.showlist = data;
        this.showlist_ready = true;
      })

    }
  },

  methods:{

  }
}
</script>

<style scoped>

</style>
