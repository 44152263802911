<template>
  <div class="pa-5">
    <div v-if="show_ready">


      <template>
        <v-card
            class="mx-auto"
            max-width="344"
        >
          <v-img
              src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
              height="200px"
          ></v-img>

          <v-card-title>
            {{ show.name }} {{show.age}}
          </v-card-title>

          <v-card-subtitle>
            {{ show.genre }} {{show.time}}
          </v-card-subtitle>

          <v-card-actions>
            <v-btn
                color="orange lighten-2"
                text
            >
              Подробнее
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
                icon
                @click="more = !more"
            >
              <v-icon>{{ more ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
          </v-card-actions>

          <v-expand-transition>
            <div v-show="more">
              <v-divider></v-divider>

              <v-card-text>
                {{show.description}}
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </template>






    </div>

  </div>
</template>

<script>
export default {
  name: "Show",

  data: () => ({

    id: 7,



    show_ready: false,
    show: {},

    more: false,

  }),

  created() {

    if (this.$store.getters.isAuthenticated){

      fetch(this.$store.state.auth.path+"/show/"+this.id+"", {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer '+ this.$store.state.auth.token,
          'Content-Type': 'application/json'
        }
      })
          .then(response => response.json())
          .then((data) => {
            this.show = data.data;
            this.show_ready = true;
          });

    }





  },


  methods:{



  }
}
</script>

<style scoped>

</style>
