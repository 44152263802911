<template>
  <div class="about">
    <h1>This is an about page</h1>

    <show></show>

    <show-list />
  </div>

</template>



<script>
import ShowList from '../components/show/ShowList'
import Show from "@/components/show/Show";

export default {
  name: 'About',

  components: {
    Show,
    ShowList
  },
}
</script>
